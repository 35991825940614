

import { getBoxValue } from '../../Const/functions';
import arrows from '../../Const/arrows';
import { getTypoCSS, getColorsCSS } from '../../../../bpl-tools/utils/getCSS';

const Style = ({ attributes, clientId }) => {
	const { sliders, slideInnerGap, slideInnerGapDevice, titleTypo, titleColor, descTypo, descColor, titleMargin, descMargin, arrow, arrowStyle, indicator, SliderOverly, height, sliderHeight, borderRadius, margin, arrowWidth, deviceArrowWidth, arrowHeight, deviceArrowHeight, arrowRadius, btnColors, btnHovColors, btnTypo, btnPadding, btnBorder, btnRadius, direction, titleAnimation, descAnimation, btnAnimation } = attributes;

	const isVertical = 'vertical' === indicator?.direction;

	return <style dangerouslySetInnerHTML={{
		__html: `
	${getTypoCSS('', titleTypo)?.googleFontLink}
	${getTypoCSS('', descTypo)?.googleFontLink}
	${getTypoCSS('', btnTypo)?.googleFontLink}
	${getTypoCSS(`#bsbCarousel-${clientId} .bsbTitle`, titleTypo)?.styles}
	${getTypoCSS(`#bsbCarousel-${clientId} p`, descTypo)?.styles}
	${getTypoCSS(`#bsbCarousel-${clientId} .carousel-button a`, btnTypo)?.styles}

	#bsbCarousel-${clientId} .bsbCarousel {
		margin:${getBoxValue(margin)};
	}

	#bsbCarousel-${clientId} .bsbTitle{
		color: ${titleColor};
		margin: ${getBoxValue(titleMargin)};
		animation-delay: ${titleAnimation?.delay}s;
		animation-duration: ${titleAnimation?.duration}s;
	}

	#bsbCarousel-${clientId} p {
		color: ${descColor};
		margin: ${getBoxValue(descMargin)}; 
		animation-delay: ${descAnimation?.delay}s;
		animation-duration: ${descAnimation?.duration}s;
	}

	#bsbCarousel-${clientId} .carousel-button {
		animation-delay: ${btnAnimation?.delay}s;
		animation-duration: ${btnAnimation?.duration}s;
	}

	#bsbCarousel-${clientId} .carousel-button a {
		${getColorsCSS(btnColors)};
		padding: ${getBoxValue(btnPadding || {})};
		border: ${getBoxValue(btnBorder || {})};
		border-radius: ${btnRadius};
		transition:0.3s;
	}

	#bsbCarousel-${clientId} .carousel-button a:hover {
		${getColorsCSS(btnHovColors)};
		transition:0.3s;
	}

	#bsbCarousel-${clientId} .carousel-item {
		position:relative;
		height: ${sliderHeight?.desktop || height};
		border-radius: ${getBoxValue(borderRadius)};
		box-sizing: border-box;
		overflow: hidden;
	}

	#bsbCarousel-${clientId} .bsbButtonDesign .bsbArrowButton {
		${getColorsCSS(arrow)};
		font-size:${arrow?.size}px;
		width:${deviceArrowWidth?.desktop || arrowWidth};
		height:${deviceArrowHeight?.desktop || arrowHeight};
		border-radius:${getBoxValue(arrowRadius)};
	}

	@media (max-width: 768px) {
		#bsbCarousel-${clientId} .bsbButtonDesign .bsbArrowButton {
			width:${deviceArrowWidth?.tablet || deviceArrowWidth?.desktop || arrowWidth};
			height:${deviceArrowHeight?.tablet || deviceArrowHeight?.desktop || arrowHeight};
		}
	}

	@media (max-width: 576px) { 
		#bsbCarousel-${clientId} .bsbButtonDesign .bsbArrowButton { 
			width:${deviceArrowWidth?.mobile || deviceArrowWidth?.tablet || deviceArrowWidth?.desktop || arrowWidth};
			height:${deviceArrowHeight?.mobile || deviceArrowHeight?.tablet || deviceArrowHeight?.desktop || arrowHeight};
		}
	}

	#bsbCarousel-${clientId} .default .bsbButtonDesign button{
		width:calc(40px + ${deviceArrowWidth?.desktop || arrowWidth});
	}

	@media (max-width: 768px) {
		#bsbCarousel-${clientId} .default .bsbButtonDesign button {
			width:calc(40px + ${deviceArrowWidth?.tablet || deviceArrowWidth?.desktop || arrowWidth});	 
		}
	}

	@media (max-width: 576px) { 
		#bsbCarousel-${clientId} .default .bsbButtonDesign button { 
			width:calc(40px + ${deviceArrowWidth?.mobile || deviceArrowWidth?.tablet || deviceArrowWidth?.desktop || arrowWidth});
		}
	}

	#bsbCarousel-${clientId} .carousel-item:after{
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: ${SliderOverly};
	}

	#bsbCarousel-${clientId} .carousel-indicators {
    	grid-template-${isVertical ? 'rows' : 'columns'}: repeat(${sliders.length}, minmax(auto, ${isVertical ? indicator?.height : indicator?.width}));
		padding: ${isVertical ? '5% 0' : '0 5%'};
	}

	#bsbCarousel-${clientId} .horizontal button {
		transform: translateY(${indicator?.moveFromEdge});
	}
	#bsbCarousel-${clientId} .vertical button {
		transform: translateX(${indicator?.moveFromEdge});
	}

	#bsbCarousel-${clientId} .carousel-indicators {
		flex-direction: ${"vertical" === indicator?.direction ? 'column' : 'row'};
	}

	#bsbCarousel-${clientId} .carousel-indicators button{
		max-width: ${indicator?.width} !important;
		max-height: ${indicator?.height} !important;
		background-color:${indicator.color};
		border: ${indicator?.border?.width || '0px'} solid transparent;
		border-radius: ${indicator?.radius};
	}

	#bsbCarousel-${clientId} .carousel-indicators button.active{
		background-color:${indicator?.activeColor};
		border: ${getBoxValue(indicator?.activeBorder || {})};
	}

	#bsbCarousel-${clientId} .arrowMouseEffect .carousel-control-prev:hover{
		cursor: url("data:image/svg+xml;utf8,${arrows[arrowStyle].left(arrow?.size, arrow?.color, direction)}"), default;
	}

	#bsbCarousel-${clientId} .arrowMouseEffect .carousel-control-next:hover{
		cursor: url("data:image/svg+xml;utf8,${arrows[arrowStyle].right(arrow?.size, arrow?.color, direction)}"), default;
	}

	@media (max-width: 768px) {
		#bsbCarousel-${clientId} .carousel-item {
			height: ${sliderHeight?.tablet || sliderHeight?.desktop || height};
		}
	}

	@media (max-width: 576px) { 
		#bsbCarousel-${clientId} .carousel-item { 
			height: ${sliderHeight?.mobile || sliderHeight?.tablet || sliderHeight?.desktop || height};
		}
	}

	#bsbCarousel-${clientId} .carousel-item .carousel-caption {
		width:calc(100% - ${slideInnerGapDevice?.desktop || slideInnerGap});
	}

	@media (max-width: 768px) { 
		#bsbCarousel-${clientId} .carousel-item .carousel-caption {
			width:calc(100% - ${slideInnerGapDevice?.tablet || slideInnerGapDevice?.desktop || slideInnerGap});
		}
	}

	@media (max-width: 576px) { 
		#bsbCarousel-${clientId} .carousel-item .carousel-caption {
			width:calc(100% - ${slideInnerGapDevice?.mobile || slideInnerGapDevice?.tablet || slideInnerGapDevice?.desktop || slideInnerGap});
		}
	}

	`.replace(/\s+/g, ' ')
	}} />
}
export default Style;